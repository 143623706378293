import React, { useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../images/project/img1.png";
import img2 from "../images/project/img2.png";
import img3 from "../images/project/img3.png";
import img4 from "../images/project/img4.png";
import img5 from "../images/project/img5.png";
import img6 from "../images/project/img6.png";
import see_more from "../images/icon/arrow-see-more.svg";
import modal from "../images/project/modal.png";
import close_icon from "../images/icon/Close.svg";

const Portfolio = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [displayedDataCount, setDisplayedDataCount] = useState(6);
  const [allDataDisplayed, setAllDataDisplayed] = useState(false);

  const handleLoadMore = () => {
    setDisplayedDataCount((prevCount) => prevCount + 2);
    if (displayedDataCount + 2 >= data.length) {
      setAllDataDisplayed(true);
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const data = [
    {
      image: img1,
      title: `Izzibook`,
      caption: `Software as a service (SaaS)`,
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
      modal: modal,
      modalintro: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      modalDesc: `Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.`,
    },
    {
      image: img2,
      title: `Lapak Mobil`,
      caption: `Mobile apps`,
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.`,
      modal: modal,
      modalintro: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      modalDesc: `Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.`,
    },
    {
      image: img3,
      title: `Rapa Tech`,
      caption: `Mobile apps`,
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.`,
      modal: modal,
      modalintro: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      modalDesc: `Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.`,
    },
    {
      image: img4,
      title: `Law Firm`,
      caption: `Software as a service (SaaS)`,
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.`,
      modal: modal,
      modalintro: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      modalDesc: `Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.`,
    },
    {
      image: img5,
      title: `Sales Apps`,
      caption: `Mobile apps`,
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.`,
      modal: modal,
      modalintro: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      modalDesc: `Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.`,
    },
    {
      image: img6,
      title: `Bomiso`,
      caption: `Mobile apps`,
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.`,
      modal: modal,
      modalintro: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      modalDesc: `Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.`,
    },
  ];

  return (
    <>
      <div
        className="my-4 py-4 bg-gradient-to-b from-[#F3F6F9] to-transparent"
        id="portfolio"
      >
        <div className="mx-4 sm:mx-4">
          <div className="flex flex-col gap-4 my-14 md:my-2">
            <h2 className="my-2 text-center text-xl  lg:text-md text-[#3275B1] font-bold">
              Project and portfolio
            </h2>
            <h1 className="my-2 max-w-auto   md:max-w-[42%] lg:max-w-[22%] text-center text-3xl md:mx-auto md:text-4xl  text-black-900  font-bold  mx-10">
              We serve clients with ground breaking solution
            </h1>
            <p className="my-2  mx-auto text-center text-lg text-gray-400  font-bold md:max-w-[55%]">
              We greatly appreciate the support and kind words from our users.
              Your feedback fuels our passion to continuously improve and
              innovate. Thank you for being part of our journey!
            </p>
          </div>

          <div className="mx-4 md:mx-16 my-20">
            <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5">
              {data.slice(0, displayedDataCount).map((item, index) => (
                <div
                  key={index}
                  className={`bg-white rounded-lg border relative transition-transform transform hover:scale-90 ${
                    index % 4 === 0 || index % 4 === 3
                      ? "md:col-span-1 lg:col-span-3"
                      : "md:col-span-1 lg:col-span-2"
                  }`}
                  onClick={() => handleItemClick(item)}
                >
                  <div className="flex flex-col">
                    <div className="relative">
                      <img
                        src={item.image}
                        alt=""
                        className="mb-4 rounded-lg object-cover h-[400px] md:h-auto w-full"
                      />
                      <p className="absolute bottom-[40px] right-4 text-white bg-black bg-opacity-40 px-2 py-1 rounded-lg">
                        {item.caption}
                      </p>
                    </div>
                    <div className="mx-5 flex flex-col gap-2">
                      <h2 className="text-lg font-bold">{item.title}</h2>
                      <p className="text-gray-700 overflow-hidden line-clamp-3">
                        {item.desc}
                      </p>
                      <p className="text-[#F0443D] font-bold mb-2 cursor-pointer flex items-center">
                        See more <img src={see_more} alt="" className="ml-1" />
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {!allDataDisplayed && (
            <button
              className="block mx-auto mt-4 px-4 py-2 bg-[##D0D5DD]  border-[#D0D5DD] border-solid border-2 text-[#344054] font-bold rounded hover:bg-[#F0443D]"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          )}
        </div>
      </div>

      {showModal && selectedItem && (
        <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto flex justify-center items-start  bg-[#171717] bg-opacity-90">
          <div className="absolute inset-0"></div>
          <div className="relative w-full max-w-6xl mx-10 md:mx-20 my-20">
            <div
              className="bg-white rounded-xl shadow-md  flex flex-col  animate-slideIn"
              style={{ scrollbarWidth: "thin" }}
            >
              <div className="mx-10 md:mx-40 my-10">
                <button
                  className="absolute top-0 right-0 px-3 py-1 font-bold rounded-xl hover:bg-[#F0443D]"
                  onClick={closeModal}
                >
                  <img
                    src={close_icon}
                    alt=""
                    style={{
                      width: "30px",
                      height: "50px",
                    }}
                  />
                </button>
                <h2 className="text-xl md:text-2xl  text-center mb-4 md:mb-12 font-bold">
                  {selectedItem.title}
                </h2>
                <div className="flex-grow">
                  <img
                    src={selectedItem.image}
                    alt=""
                    className="mb-4 rounded-lg"
                  />
                  <p className="text-gray-700 mb-4 text-sm md:text-md">
                    {selectedItem.desc}
                  </p>
                  <div className="flex items-center justify-center mt-6">
                    <img
                      src={selectedItem.modal}
                      alt=""
                      className="cursor-pointer mb-4 rounded-lg"
                    />
                  </div>
                  <p className="text-gray-700 mt-4 text-sm md:text-md">
                    {selectedItem.modalDesc}
                  </p>
                  <div className="flex items-center justify-center mt-6"></div>
                  <p className="text-gray-700 mt-4 text-sm md:text-md">
                    {selectedItem.modalDesc2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Portfolio;
