import React, { useState, useEffect } from "react";
import NavLinks from "../NavBar/NavLinks";
import { HashLink } from "react-router-hash-link";
import Logo from "../../images/logo.png";
import "../../../src/index.css";

const NavBar = () => {
  const [top, setTop] = useState(!window.scrollY);
  const [isOpen, setisOpen] = React.useState(false);
  function handleClick() {
    setisOpen(!isOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <nav
      className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${
        !top && "bg-white shadow-lg"
      }`}
    >
      <div className="flex flex-row justify-between items-center py-2 mx-2">
        <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
          <HashLink smooth to="/#hero">
            <img src={Logo} alt="" className="max-w-40 h-auto md:max-w-54" />
          </HashLink>
        </div>

        <div className="flex flex-row lg:flex-row-reverse justify-center">
          <div className="my-auto mr-2">
            <HashLink
              className="button px-4 font-extrabold text-gray-500 hover:text-[#3275B1]"
              to="https://wa.me/6281110007770"
              smooth={true}
              duration={500}
              style={{ cursor: "pointer", fontSize: "12px" }}
            >
              Contact Us
            </HashLink>
          </div>
          <div className="group flex flex-col items-center">
            <button
              className="p-2 rounded-lg lg:hidden text-blue-900"
              onClick={handleClick}
            >
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                {isOpen && (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                  />
                )}
                {!isOpen && (
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  />
                )}
              </svg>
            </button>
            <div className="hidden space-x-6 lg:inline-block p-5">
              <NavLinks />
            </div>

            <div
              className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white lg:hidden shadow-xl top-14 ${
                isOpen ? "block" : "hidden"
              } `}
            >
              <div className="flex flex-col space-y-6">
                <NavLinks />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
