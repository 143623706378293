import React from "react";
import imgFooter from "../images/footer-logo.png";
import fb from "../images/icon/fb.svg";
import insta from "../images/icon/insta.svg";
import twitter from "../images/icon/twitter.svg";
import linkedin from "../images/icon/linkedin.svg";
import call from "../images/icon/call.svg";
import email from "../images/icon/email.svg";

const Footer = () => {
  return (
    <>
      <footer className="bg-[#191919]">
        <div className="max-w-full px-4 sm:px-6 border-b py-8">
          <div className="max-w-full px-4 sm:px-6 py-8 mx-auto">
            <div className="text-white flex flex-col sm:flex-col md:flex-row md:justify-between gap-4">
              <div className="mb-2 md:mb-0 sm:w-full md:w-1/4">
                <div className="text-md font-medium text-gray-400 ">
                  <div className="mb-4">
                    <img
                      src={imgFooter}
                      alt=""
                      style={{
                        width: "200px",
                        height: "auto",
                        top: "0",
                      }}
                    />
                  </div>

                  <p>
                    PT. Gudang Aplikasi Nusantara, as a consultant and developer
                    in the field of Information Technology, strives to provide
                    effective and efficient solutions to information technology
                    problems and needs.
                  </p>
                </div>
              </div>
              <div className="mb-2 md:mb-0 sm:w-full md:w-1/4">
                <h6>Address</h6>
                <div className="text-gray-400">
                  <div className="mb-4">
                    Ruko Sunter Prima, No. 6A, Jakarta Utara, <br /> 14350, DKI
                    Jakarta.
                  </div>
                  <div className="flex items-center gap-2 mb-4">
                    <a
                      href="https://wa.me/6281110007770"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2"
                    >
                      <img
                        src={call}
                        alt="Call Icon"
                        style={{ width: "16px", height: "16px" }}
                      />
                      <div>(+62) 81110007770</div>
                    </a>
                  </div>

                  <div className="flex items-center gap-2">
                    <a
                      href="mailto:info@gudangapps.co.id"
                      className="flex items-center gap-2"
                    >
                      <img
                        src={email}
                        alt="Email Icon"
                        style={{ width: "16px", height: "16px" }}
                      />
                      <div>info@gudangapps.co.id</div>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="mb-2 md:mb-0 sm:w-full md:w-1/6">
                <h6 className="text-white text-xl font-bold mb-4">Follow us</h6>
                <div className="flex items-center gap-4">
                  <a
                    href="https://www.facebook.com/"
                    className="text-white hover:text-gray-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={fb} alt="Facebook" />
                  </a>
                  <a
                    href="https://twitter.com/"
                    className="text-white hover:text-gray-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                  <a
                    href="https://www.instagram.com/"
                    className="text-white hover:text-gray-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={insta} alt="Instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/"
                    className="text-white hover:text-gray-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedin} alt="LinkedIn" />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="max-w-full mx-auto px-4 sm:px-6 py-8 md:px-8 lg:px-0 lg:mx-48">
          <div className="flex flex-col lg:flex-row justify-between items-center text-[#7A7A7A] text-sm md:text-lg">
            <div className="flex flex-row gap-8 mb-4 sm:mb-0 ">
              <a href="/">Privacy policy</a>
              <a href="/">Legal notice</a>
              <a href="/">Terms of service</a>
            </div>
            <div>© Copyright 2024 - GudangAplikasiDigital</div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
