import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar/NavBar";
import heroImg from "../images/bg.png";
import device from "../images/device.png";
import Modal from "./Modal";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { ScheduleDemo } from "../services/schedule";
import TickCircleIcon from "../images/icon/TickCircleIcon";
import TickCloseIcon from "../images/icon/TickCloseIcon";


const Hero = () => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [valRegis, setValRegis] = useState({
    Name: '',
    Phone: '',
    Email: '',
    Message: ''
  });
  const [messageModal, setMessageModal] = useState({
    title: '',
    subTitle: '',
    error: false,
  });

  useEffect(() => {
    loadCaptchaEnginge(6, '', 'white', 'lower');
  }, []);

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [valRegis, captchaValue]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Membersihkan overflow saat komponen unmount atau visibility berubah
    return () => {
      document.body.style.overflow = '';
    };
  }, [showModal]);

  const checkFormValidity = () => {
    const { Name, Phone, Email, Message } = valRegis;
    const isCaptchaValid = captchaValue.trim().length === 6;
    
    return (
      Name.trim() !== '' &&
      Phone.trim() !== '' &&
      Email.trim() !== '' &&
      Message.trim() !== '' &&
      isCaptchaValid
    );
  };  

  const handleRegisChange = (e) => {
    const { name, value } = e.target;
    let valRegisPhone = value;

    if (name === 'Email') {
      if (!validateEmail(value)) {
        setError('Email tidak valid');
      } else {
        setError('');
      }
    }

    if (name === 'Phone') {
      valRegisPhone = value.replace(/\D/g, '');
    }


    setValRegis(prevState => ({
      ...prevState,
      [name]: valRegisPhone
    }));
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return re.test(String(email).toLowerCase());
  };

  const handleChange = (event) => {
    setCaptchaValue(event.target.value);
  };

  const handleClear = () => {
    setMessageModal(prevState => ({
      ...prevState,
      title: '',
      subTitle: '',
      error: false,
    }));
    setCaptchaValue('');
  };

  const doSubmit = async () => {
    if (validateCaptcha(captchaValue)) {
      try {
        const response = await ScheduleDemo(valRegis);
        if (response && response.data.status == '1') {
          setShowModal(false);
          setMessageModal({
            title: 'Request for schedule demo sent successfully',
            subTitle: 'Thanks for placing your trust in us. We deeply appreciate your confidence and look forward to exceeding your expectations.',
            error: false,
          });
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
            handleClear();
          }, 2000);
          setValRegis({
            Name: '',
            Phone: '',
            Email: '',
            Message: ''
          });
        } else {
          handleSubmissionError();
        }
      } catch (error) {
        handleSubmissionError();
      }
    } else {
      handleInvalidCaptcha();
    }
  };

  const handleSubmissionError = () => {
    setShowModal(false);
    setMessageModal({
      title: 'Failed',
      subTitle: 'Something went wrong. Please try again later.',
      error: true,
    });
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
      handleClear();
      setShowModal(true);
    }, 2000);
  };

  const handleInvalidCaptcha = () => {
    setShowModal(false);
    setMessageModal({
      title: 'Failed',
      subTitle: 'Captcha not valid. Please try again.',
      error: true,
    });
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
      handleClear();
      setShowModal(true);
    }, 2000);
  };

  return (
    <>
      <div className="hero" id="hero">
        <div>
          <NavBar />
        </div>
        <div
          className="m-auto overflow-hidden mt-16 lg:mt-24 bg-cover bg-center"
          style={{
            backgroundImage: `url(${showModal || isSuccess ? heroImg : `${heroImg}?${new Date().getTime()}`})`,
            animation: "zoom-in 0.5s ease-in-out"
          }}
        >
          <div
            id="hero"
            className="flex flex-col justify-between text-center lg:40"
            style={{ height: "100%" }}
          >
            <div>
              <h1 className="mb-10 px-10 mt-10 md:text-5xl text-3xl font-bold text-white mx-1 md:mx-auto max-w-[600px]">
                Empower Your Business Partner with Us Today.
              </h1>
              <div className="text-md font-light tracking-tight mb-5 mx-3  text-white">
                <strong>Gudang Aplikasi Nusantara (GAN)</strong> elevates your
                business to new heights with our comprehensive suite of digital
                solutions <br />
                tailored to your unique needs. Our team of seasoned experts is
                dedicated to delivering top-notch software development, web
                <br />
                design, and digital marketing services that drive results.
              </div>

              <div className="flex items-center justify-center mx-auto">
                <span onClick={() => setShowModal(true)} className="text-white font-bold mr-2 md:mr-8 cursor-pointer">
                  Schedule a Demo
                </span>
                <a
                  href="https://wa.me/6281110007770"
                  className="my-auto text-white bg-[#F0443D] hover:bg-red-800 inline-flex items-center justify-center px-4 py-2 text-lg shadow-md rounded-xl sm:w-auto sm:mb-0"
                >
                  Contact Us
                </a>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={device}
                alt=""
                style={{
                  width: "80%",
                  height: "auto",
                  animation: "zoom-in 0.5s ease-in-out",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        visibility={showModal}
        modalHeader=''
        modalStyle='w-[300px] md:w-[500px] xl:w-[700px] max-h-[90vh]'
        onClose={() => setShowModal(false)}
        renderElement={<div className="flex flex-col px-10 md:px-14 xl:px-20 pb-10 gap-3">
          <div className="flex flex-col items-center gap-2">
            <p className="text-4xl font-bold text-center">Schedule a demo</p>
            <p className="text-lg font-normal text-center text-gray-500">Our friendly team of experts will be in touch to provide your personalized people strategy assessment</p>
          </div>

          <div className='flex flex-col gap-2 w-full pt-2'>
            <p className='body1semibold __ff-PJS'>Full Name:</p>
            <div className='flex items-center rounded-[12px] bg-white border-[1px] border-[#D4D7E2] py-[12px] px-[14px]'>
              <input
                type='text'
                autoComplete='off'
                name='Name'
                placeholder='Enter your first name'
                required={true}
                value={valRegis.Name}
                onChange={handleRegisChange}
                className='outline-none border-none flex-1' />
            </div>
          </div>

          <div className='flex flex-col gap-2 w-full'>
            <p className='body1semibold __ff-PJS'>Phone:</p>
            <div className='flex items-center rounded-[12px] bg-white border-[1px] border-[#D4D7E2] py-[12px] px-[14px]'>
              <input
                type='text'
                autoComplete='off'
                name='Phone'
                pattern='[0-9]*'
                inputMode='numeric'
                placeholder='Enter your phone'
                required={true}
                value={valRegis.Phone}
                onChange={handleRegisChange}
                className='outline-none border-none flex-1' />
            </div>
          </div>

          <div className='flex flex-col gap-2 w-full'>
            <p className='body1semibold __ff-PJS'>Email Address:</p>
            <div className='flex items-center rounded-[12px] bg-white border-[1px] border-[#D4D7E2] py-[12px] px-[14px]'>
              <input
                type='text'
                autoComplete='off'
                name='Email'
                placeholder='Enter your email address'
                required={true}
                value={valRegis.Email}
                onChange={handleRegisChange}
                className='outline-none border-none flex-1' />
            </div>
            {error && <div className='captionmedium __ff-PJS' style={{ color: 'red' }}>{error}</div>}
          </div>

          <div className='flex flex-col gap-2 w-full'>
            <p className='body1semibold __ff-PJS'>Message:</p>
            <div className='flex items-center rounded-[12px] bg-white border-[1px] border-[#D4D7E2] py-[12px] px-[14px]'>
              <textarea
                className="w-full outline-none border-none"
                rows={4}
                name='Message'
                placeholder='Enter your message'
                required={true}
                value={valRegis.Message}
                onChange={handleRegisChange} />
            </div>
          </div>

          <div className="flex flex-col items-center gap-2 pt-4">
            <LoadCanvasTemplate />

            <div className="col mt-1">
              <div className='flex items-center rounded-[12px] bg-white border-[1px] border-[#D4D7E2] py-[12px] px-[14px] w-max'>
                <input
                  placeholder="Enter Captcha Value"
                  id="user_captcha_input"
                  name="user_captcha_input"
                  type="text"
                  maxlength="6"
                  className='outline-none border-none flex-1'
                  value={captchaValue}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <button disabled={!isFormValid} onClick={() => doSubmit()} className="bg-teal-800 disabled:bg-[#F0F1F5] text-emerald-100 disabled:text-[#787D8F] rounded-lg px-3 py-3 mt-5">Get started</button>
        </div>}
      />

      <Modal
        visibility={isSuccess}
        onClose={() => setIsSuccess(!isSuccess)}
        modalHeader={false}
        isClose={false}
        modalStyle='max-w-[448px]'
        classModal='top-[25%]'
        renderElement={<div className='w-full flex flex-col items-center gap-8 text-center p-[20px]'>
          {messageModal.error ? <TickCloseIcon fill='#EA3D3D' width={65} height={65} /> : <TickCircleIcon fill='#00AA4F' width={65} height={65} />}
          <div className='flex flex-col gap-3'>
            <p className='text-xl text-[#2B2C32] font-semibold'>{messageModal.title}</p>
            <p className='text-[#787D8F] text-base font-normal'>{messageModal.subTitle}</p>
          </div>
          <button onClick={() => setIsSuccess(!isSuccess)} className='rounded-[24px] w-full bg-[#2B2C32] text-[#FFF] py-3 px-5'>Tutup</button>
        </div>} />
    </>
  );
};

export default Hero;