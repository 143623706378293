import React from 'react';

const TickCloseIcon = (props) => {
    return (
        <svg
            width='80'
            height='80'
            viewBox='0 0 80 80'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M39.9998 6.66667C21.6332 6.66667 6.6665 21.6333 6.6665 40C6.6665 58.3667 21.6332 73.3333 39.9998 73.3333C58.3665 73.3333 73.3332 58.3667 73.3332 40C73.3332 21.6333 58.3665 6.66667 39.9998 6.66667ZM51.1998 47.6667C52.1665 48.6333 52.1665 50.2333 51.1998 51.2C50.6998 51.7 50.0665 51.9333 49.4332 51.9333C48.7998 51.9333 48.1665 51.7 47.6665 51.2L39.9998 43.5333L32.3332 51.2C31.8332 51.7 31.1998 51.9333 30.5665 51.9333C29.9332 51.9333 29.2998 51.7 28.7998 51.2C27.8332 50.2333 27.8332 48.6333 28.7998 47.6667L36.4665 40L28.7998 32.3333C27.8332 31.3667 27.8332 29.7667 28.7998 28.8C29.7665 27.8333 31.3665 27.8333 32.3332 28.8L39.9998 36.4667L47.6665 28.8C48.6332 27.8333 50.2332 27.8333 51.1998 28.8C52.1665 29.7667 52.1665 31.3667 51.1998 32.3333L43.5332 40L51.1998 47.6667Z' 
                fill={props.fill || 'white'} />
        </svg>
    );
};

export default TickCloseIcon;