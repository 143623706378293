import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import rapa from "../images/clients/rapa.png";
import canary from "../images/clients/canary-logo.png";
import beatrice from "../images/clients/beatrice.png";
import cmm from "../images/clients/cmm.png";
import nuswapada from "../images/clients/nuswapada.png";
import wronggym from "../images/clients/wronggym.png";

const ClientLogo = ({ img }) => {
  return (
    <div
      className="overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 mx-auto"
      style={{ width: "100%", height: "100%" }}
    >
      <img
        src={img}
        alt="Client"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "auto",
          height: "auto",
          display: "block",
          margin: "auto",
        }}
      />
    </div>
  );
};

const Clients = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const data = [
    { img: rapa },
    { img: canary },
    { img: beatrice },
    { img: cmm },
    { img: nuswapada },
    { img: wronggym },
  ];

  return (
    <div className="mt-8 bg-white flex flex-col">
      <p className="flex justify-center text-md font-semibold text-gray-600 mb-6 md:mb-8">
        Join 100+ companies already growing
      </p>
      <section data-aos="fade-up">
        <div className="py-4" data-aos="fade-in" data-aos-delay="600">
          <div className="grid px-10 lg:px-0 grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 md:gap-0">
            {data.map((item, index) => (
              <ClientLogo key={index} img={item.img} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
