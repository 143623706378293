import { API_URL } from "../config/api";

export async function ScheduleDemo(body) {

    try {
        const response = await API_URL.post('cms/master/Master_produk/emailGudangApps', body);

        return response; 
    } catch (err) {
        return false;
    }
}