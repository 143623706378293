import React from "react";
import img1 from "../images/logo/businnes.png";
import img2 from "../images/logo/mobileapps.png";
import img3 from "../images/logo/information.png";
import img4 from "../images/logo/webdev.png";

const data = [
  {
    img: img1,
    title: `E-Business Solution`,
    description: `We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.`,
  },
  {
    img: img2,
    title: `Information System`,
    description: `We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.`,
  },
  {
    img: img3,
    title: `Mobile Apps`,
    description: `We provide domain registration and web hosting services to individuals and organizations to enable them gain visibility in the digital space.`,
  },
  {
    img: img4,
    title: `Web Development`,
    description: `Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.`,
  },
];

const Card = ({ img, title, description }) => (
  <div className="flex flex-col bg-white overflow-hidden mx-2 max-xl:flex-shrink-0 w-80">
    <img className="object-cover w-[54px] h-[54px]" src={img} alt={title} />
    <div className="p-4">
      <h2 className="font-bold text-xl mb-2">{title}</h2>
      <p className="text-gray-700">{description}</p>
    </div>
  </div>
);

const CardList = () => (
  <div className="flex flex-row overflow-x-auto p-4 space-x-4 md:space-x-6">
    {data.map((item, index) => (
      <Card
        key={index}
        img={item.img}
        title={item.title}
        description={item.description}
      />
    ))}
  </div>
);

const Services = () => {
  return (
    <div id="services">
      <CardList />
    </div>
  );
};

export default Services;
