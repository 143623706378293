import React from "react";
import { HashLink } from "react-router-hash-link";
import { useEffect } from "react";

const Cta = () => {
  useEffect(() => {
    const ctaElements = document.querySelectorAll(".cta-animation");
    ctaElements.forEach((element) => {
      element.style.opacity = "0";
      element.style.transform = "translateY(20px)";
      element.style.transition = "opacity 0.5s ease, transform 0.5s ease";
    });

    const revealElements = () => {
      ctaElements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight) {
          element.style.opacity = "1";
          element.style.transform = "translateY(0)";
        }
      });
    };

    window.addEventListener("scroll", revealElements);

    return () => {
      window.removeEventListener("scroll", revealElements);
    };
  }, []);

  return (
    <div
      className="w-full flex items-center justify-center h-[50vh]"
      id="contact-us"
    >
      <div className="md:max-w-[30%] mx-auto text-center px-4 lg:px-0">
        <p
          className="text-4xl lg:text-5xl font-bold mb-4 cta-animation items-center"
          style={{ animationDelay: "0.3s" }}
        >
          Grow your business with us now
        </p>
        <p
          className="text-base lg:text-lg mb-8 cta-animation"
          style={{ animationDelay: "0.6s" }}
        >
          Customize and build to fit with your business model. We offer you the
          best quality product.
        </p>
        <a
          href="https://wa.me/6281110007770"
          className="my-auto text-white bg-[#F0443D] hover:bg-red-800 inline-flex items-center justify-center px-6 py-3 text-lg shadow-md rounded-xl sm:w-auto sm:mb-0 cta-animation"
          style={{ animationDelay: "0.9s" }}
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Cta;
