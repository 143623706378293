import React from "react";
import Clients from "../components/Clients";
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import About from "../components/About";

const Home = () => {
  return (
    <>
      <Hero />
      <div className="md:px-20 lg:px-40">
        <Clients />
        <Intro />
        <Services />
      </div>
      <Portfolio />
      <About />
      <Cta />
      <Footer />
    </>
  );
};

export default Home;
