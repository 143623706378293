import React, { useEffect, useRef } from "react";
import Aboutbg from "../images/bg-about.png";
import Aftersales from "../images/icon/after-sales.svg";
import Highend from "../images/icon/high-end.svg";
import Userreq from "../images/icon/user-req.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon1 from "../images/testimonial/logo1.svg";
import icon2 from "../images/testimonial/logo2.svg";
import icon3 from "../images/testimonial/logo3.svg";
import background from "../images/testimonial/bg.png";
import left from "../images/testimonial/arrow-left.svg";
import right from "../images/testimonial/arrow-right.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  const sliderRef = useRef(null);

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerPadding: "10%",
        },
      },
    ],
  };

  const AboutCard = ({ image, title, description }) => {
    return (
      <div
        className="flex flex-row md:flex-row gap-2 md:my-4 text-center lg:text-left lg:my-0 lg:justify-center w-full lg:w-[30%] justify-center md:mx-auto"
        data-aos="fade-up"
      >
        <div className="lg:mb-0 lg:mr-3 md:w-16 h-24 rounded-full">
          <img
            src={image}
            alt={title}
            className="rounded-full"
            style={{ objectPosition: "top" }}
          />
        </div>
        <div className="flex flex-col w-[70%]">
          <div>
            <p className="text-md md:text-lg font-bold text-[#CCD4E3] text-start">
              {title}
            </p>
          </div>
          <div>
            <p className="text-md md:text-lg text-[#CCD4E3] text-start">
              {description}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const AboutView = () => {
    const about = [
      {
        image: Userreq,
        title: "User Requirements Analysis",
        description:
          "We design the system according to the needs and problems of the client. So you get the right solution.",
      },
      {
        image: Highend,
        title: "High End Technology",
        description:
          "We always carry out ongoing research to always be up to date with the latest technological developments.",
      },
      {
        image: Aftersales,
        title: "After Sales Service & Maintenance",
        description:
          "We provide after sales service guarantees and assistance to ensure users utilize services and products as expected.",
      },
    ];

    return (
      <div className=" md:p-0 mb-20 flex flex-wrap justify-center gap-8 md:gap-2 md:mx-36 md:my-20">
        {about.map((item, index) => (
          <AboutCard
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="w-full mt-2 about" id="about">
        <div
          className="m-auto overflow-hidden mt-2 md:mt-10 p-2 md:p-12 bg-cover bg-center"
          style={{
            height: "100%",
            backgroundImage: `url(${Aboutbg}?${new Date().getTime()})`,
          }}
          data-aos="zoom-in"
        >
          <div className="">
            <div
              className="flex flex-col md:my-10 md:mt-10 lg:flex-row py-8 justify-center lg:text-left mx-5"
              data-aos="fade-up"
            >
              <div className="flex w-full lg:w-[40%]">
                <p className="text-2xl md:text-4xl font-bold text-[#FFFFFF]">
                  About us
                </p>
              </div>
              <div
                className="flex flex-col my-4 lg:my-0 lg:justify-end w-full lg:w-[40%]"
                data-aos="fade-in"
                data-aos-delay="500"
              >
                <div>
                  <p className="text-md md:text-lg text-[#CCD4E3] text-start">
                    GAN is a software house and IT solution company that
                    provides service in the development of systems applications
                    to deliver solutions and support clients in the process of
                    achieving targets and goals. Our products are customized and
                    built to fit the client's business needs
                  </p>
                </div>
              </div>
            </div>
            <AboutView />
            <div
              className="mb-8 md:mb-24  text-center mx-2 md:mx-auto text-[#FFFFFF] md:max-w-[60%]"
              data-aos="fade-up"
            >
              <p className="text-xl md:text-4xl font-bold my-4">Testimonial</p>
              <p className="text-3xl md:text-4xl font-extrabold my-4">
                Our User Kind Words
              </p>
              <p className="text-[#BDC3D0] text-md md:text-xl mt-8">
                We greatly appreciate the support and kind words from our users.
                Your feedback fuels our passion to continuously improve and
                innovate. Thank you for being part of our journey!
              </p>
            </div>
            <div className="sm:mx-40 md:mx-40 xl:mx-40">
              <div className="relative">
                <Slider ref={sliderRef} {...settings}>
                  {data.map((testimonial, index) => (
                    <div key={index}>
                      <div
                        className="transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-xl shadow-xl p-1 bg-cover bg-center mx-5"
                        style={{ backgroundImage: `url(${background})` }}
                      >
                        <div className="m-2 text-justify text-sm bg-opacity-75 p-4 rounded-lg">
                          <div className="flex flex-col">
                            <p
                              className="text-sm text-start font-medium leading-5 h-auto md:h-40 text-[#F3F5F7] border-b pb-4"
                              style={{ lineHeight: "1.5" }}
                            >
                              {testimonial.comment}
                            </p>

                            <div className="flex flex-row gap-4 mt-[22px]">
                              <img
                                src={testimonial.img}
                                alt=""
                                className="w-12 h-12 rounded-full"
                              />
                              <div className="flex flex-col h-24 gap-2">
                                <p className=" text-[#FFFFFF]">
                                  {testimonial.name}
                                </p>
                                <p className=" text-[#636C7D]">
                                  {testimonial.job}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                <div className="flex justify-center mt-8 mb-4 md:mt-8 ">
                  <button
                    onClick={handlePrev}
                    className="mr-4 bg-[#1A202C] rounded-full w-12 h-12 flex justify-center items-center transition-colors duration-300 hover:bg-[#F0443D] hover:text-white"
                    style={{
                      backgroundImage: `url(${left})`,
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></button>
                  <button
                    onClick={handleNext}
                    className="mr-4 bg-[#1A202C] rounded-full w-12 h-12 flex justify-center items-center transition-colors duration-300 hover:bg-[#F0443D] hover:text-white"
                    style={{
                      backgroundImage: `url(${right})`,
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const data = [
  {
    name: `Paula Komarudin`,
    comment: `They provide the advance technology combine with vast knowledge inline with the current investmnet trend enables us to be more competitive in the capital market industry.`,
    img: icon1,
    job: `President Director of Ciptadana Asset Management`,
  },
  {
    name: `Caterine Gouw`,
    comment: `Finally we have an IT partner that actually LISTENS and respond to all of questions very quick.`,
    img: icon2,
    job: `Director of Jarvis`,
  },
  {
    name: `Rudy`,
    comment: `Redsoft has been good at understanding requirements and putting together a finished product.`,
    img: icon3,
    job: `Director of Nusadana`,
  },
  {
    name: `Jhon Doe`,
    comment: `Redsoft has been good at understanding requirements and putting together a finished product.`,
    img: icon3,
    job: `Director of Instagram`,
  },
  {
    name: `Bill Gates`,
    comment: `Redsoft has been good at understanding requirements and putting together a finished product.`,
    img: icon3,
    job: `Owner of Apple`,
  },
];

export default About;
