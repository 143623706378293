import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

const NavLinks = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <Link
        className="px-4 font-extrabold text-gray-500 hover:text-[#3275B1]"
        style={{ cursor: "pointer", fontSize: "20px" }} // Ubah ukuran font di sini
        onClick={scrollToTop}
        smooth={true}
        duration={500}
      >
        Home
      </Link>
      <Link
        className="px-4 font-extrabold text-gray-500 hover:text-[#3275B1]"
        to="services"
        smooth={true}
        duration={500}
        style={{ cursor: "pointer", fontSize: "20px" }} // Ubah ukuran font di sini
      >
        Services
      </Link>
      <Link
        className="px-4 font-extrabold text-gray-500 hover:text-[#3275B1]"
        to="portfolio"
        smooth={true}
        duration={500}
        style={{ cursor: "pointer", fontSize: "20px" }} // Ubah ukuran font di sini
      >
        Portfolio
      </Link>
      <Link
        className="px-4 font-extrabold text-gray-500 hover:text-[#3275B1]"
        to="about"
        smooth={true}
        duration={500}
        style={{ cursor: "pointer", fontSize: "20px" }} // Ubah ukuran font di sini
      >
        About
      </Link>
    </>
  );
};

export default NavLinks;
