import React, { Fragment } from 'react';
import CloseIcon from '../images/icon/Close.svg';

function Modal({ renderElement, visibility, onClose, isClose = true, modalTitle = 'This is modal', modalStyle = 'w-max', modalHeader = true, classModal = 'top-[50%]' }) {
    return (
        <Fragment>
            {/* backdrop filter */}
            <div onClick={onClose} className={`bg-neutral-950/[0.5] fixed top-0 left-0 w-[100%] h-[100%] z-50 ${visibility ? 'opacity-100' : 'opacity-0 invisible'} transition-opacity ease-in-out duration-200`} />

            {/* modal content */}
            <div className={`fixed ${classModal} left-[50%] translate-x-[-50%] translate-y-[-50%] ${visibility ? 'opacity-100' : 'opacity-0 invisible hidden'} transition-opacity ease-in-out duration-200 z-[51]`}>
                <div className={`bg-white shadow-lg ${modalStyle} rounded-[20px] overflow-auto pc:overflow-hidden`}>
                    <div className={`sticky flex ${modalHeader ? 'justify-between' : 'justify-end'} items-center px-4 pb-4 pt-5`}>
                        {modalHeader &&
                            <h3 className='body1bold pc:title2bold __ff-PJS tracking-wider'>{modalTitle}</h3>
                        }
                        {isClose && 
                            <div className='cursor-pointer' onClick={onClose}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        }
                    </div>
                    {renderElement}
                </div>
            </div>
        </Fragment>
    );
}

export default Modal;