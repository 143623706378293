import React from "react";

const Intro = () => {
  return (
    <>
      <div className="mx-6 md:mx-20 md:my-20">
        <p className="text-[#3275B1] font-bold text-md md:text-xl ">
          Our Services
        </p>
        <div className="flex flex-col lg:flex-row py-8 justify-between lg:text-left">
          <div className="flex flex-col justify-center lg:w-[35%] ">
            <p className="text-3xl font-bold text-start md:text-4xl">
              Browse our services & offerings
            </p>
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-[55%]"
            style={{
              animation: "fade-in-zoom 1s ease-in-out",
              animationDelay: "0.5s",
            }}
          >
            <div>
              <p
                className="text-sm text-[#667085] text-start md:text-lg"
                style={{ lineHeight: "1.5" }}
              >
                Explore Our Software Wonderland! Unlock Innovation from Our
                House. Navigate Through Our Tech Suite. Dive into Cutting-Edge
                Solutions. Unlock Your Business Potential Today. Let's Dive into
                Software Solutions Together!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
